<template>
	<section id="cases">
		<div class="inner">
			<div id="case-filters" v-if="filters.length">
				<button v-for="filter, index in filters" :key="`filter-${index}`" class="ui-btn ui-btn-outline ui-btn-rounded" :class="{'active': gender === filter}" @click="filterCases(filter)" :disabled="isLoading || gender === filter" v-text="filter"></button>
			</div>
			<div class="list-cases col-row auto-col-clear" v-if="posts.length">
				<div class="col xl6 l6 m12 s12" v-for="post, index in posts" :key="`post-${index}`">
					<div class="case">
						<figure class="img bg" :style="{backgroundImage: `url('${post.image}')`}"></figure>
						<div class="content">
							<div class="type" v-text="post.type"></div>
							<h3 class="title" v-html="post.title"></h3>
							<p class="desc" v-html="post.content"></p>
							<a :href="post.guid" class="ui-btn ui-btn-outline" v-text="'Read ' + (post.case_type == 'Testimonial' ? 'Testimonial' : 'Story')"></a>
						</div>
					</div> 
				</div>
			</div>
			<div class="no-posts ui-text-center" v-if="!total_posts && !isLoading">
				<p>No case studies</p>
			</div>
		</div>
		<div class="load-more ui-text-center" v-if="total_posts || isLoading">
			You are viewing <span class="viewing" v-text="posts.length"></span> of <span class="total" v-text="total_posts"></span> case studies
			<div class="progress-wrapper">
				<div class="progress">
					<div class="bar">&nbsp;</div>
				</div>
			</div>
			<button type="button" class="ui-btn ui-btn-outline" @click="loadMore" :disabled="isFinished || isLoading" v-text="isLoading ? 'Loading...' : 'Load more'"></button>
		</div>
	</section>
</template>
<script>
	import {postsApi} from '../../../api';
	import helper from '../../../helpers';
	export default {
		data() {
			return {
				page: 1,
				per_page: 6,
				total_pages: 0,
				total_posts: 0,
				posts: [],
				isLoading: false,
				isFinished: false,
				filters: [
					"Men",
					"Women"
				],
				gender: '',
			}
		},
		methods: {
			getCases() {
				let self = this;
				let args = {
					page: self.page,
					per_page: self.per_page,
					image_size: 'full',
					gender: self.gender
				}
				self.isLoading = true;
				postsApi.getCases(args).then(response => {
					let data = response.data, percent = 0;
					self.isLoading = false;
					self.total_pages = data.total_pages;
					self.total_posts = data.total_posts;
					percent = (100 / (data.total_posts / (self.per_page * self.page))).toFixed(0);
					if(data.total_posts === 0) {
						self.isFinished = true;
					} else {
						self.posts.push.apply(self.posts, data.posts);
						if(self.page === data.total_pages) {
							self.isFinished = true;
						}
						self.page += 1;
					}
					$('.progress .bar').width(percent + '%');
				}, error => {
					console.log(error);
				});
			},
			loadMore() {
				let self = this;
				self.getCases();
			},
			initMounted() {
				let self = this;
				self.getCases();
			},
			filterCases(gender) {
				let self = this;
				self.gender = gender;
				self.reset();
			},
			reset() {
				let self = this;
				self.page = 1;
				self.total_pages = 0;
				self.posts = [];
				self.isFinished = false;
				$('.progress .bar').width(0);
				self.initMounted();
			}
		},
		computed: {
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>