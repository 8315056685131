<template>
	<section id="tax-posts" class="section-spacing">
		<div class="inner">
			<div class="col-row" v-if="windowSize > 961">
				<div class="col l6 m12 s12">
					<div class="col-row auto-col-clear">
						<div class="col l12 m2 s2">
							<div class="post post-block main-post" :class="posts[0].post_type === 'video' || posts[0].post_type === 'podcast' ? 'video-post' : ''">
								<figure class="img respond">
									<a :href="posts[0].post_guid">
										<img :src="posts[0].post_image.replace('-165x115', '')" :alt="posts[0].post_title" />
									</a>
								</figure>
								<div class="content">
									<div class="meta">
										<time class="date" v-text="posts[0].post_date"></time>
										<a :href="posts[0].post_cat.slug" class="cat" v-html="posts[0].post_cat.name + ' /'"></a>
										<span v-if="posts[0].post_type" v-text="posts[0].post_type"></span>
									</div>
									<h3 class="title">
										<a :href="posts[0].post_guid" v-html="posts[0].post_title"></a>
									</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col l6 m12 s12">
					<div class="col-row auto-col-clear">
						<div class="col l4 m2 s2" v-for="post, index in sidePosts" :key="`post-${index}`">
							<div class="post post-block" :class="post.post_type === 'video' || post.post_type === 'podcast' ? 'video-post' : ''">
								<figure class="img respond">
									<a :href="post.post_guid">
										<img :src="post.post_image" :alt="post.post_title" />
									</a>
								</figure>
								<div class="content">
									<div class="meta">
										<time class="date" v-text="post.post_date"></time>
										<a :href="post.post_cat.slug" class="cat" v-html="post.post_cat.name + ' /'"></a>
										<span v-if="post.post_type" v-text="post.post_type"></span>
									</div>
									<h3 class="title">
										<a :href="post.post_guid" v-html="post.post_title"></a>
									</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-row auto-col-clear">
				<div class="col l2 m3 s6 xs12" v-for="post, index in allPosts" :key="`post-${index}`">
					<div class="post post-block" :class="post.post_type === 'video' || post.post_type === 'podcast' ? 'video-post' : ''">
						<figure class="img respond">
							<a :href="post.post_guid">
								<img :src="post.post_image" :alt="post.post_title" />
							</a>
						</figure>
						<div class="content">
							<div class="meta">
								<time class="date" v-text="post.post_date"></time>
								<a :href="post.post_cat.slug" class="cat" v-html="post.post_cat.name + ' /'"></a>
								<span v-if="post.post_type" v-text="post.post_type"></span>
							</div>
							<h3 class="title">
								<a :href="post.post_guid" v-html="post.post_title"></a>
							</h3>
						</div>
					</div>
				</div>
			</div>
			<div class="view-more ui-text-center" v-if="showLoadMore">
				<button type="button" class="ui-btn ui-btn-outline" @click="loadMore" :disabled="isFinished || isLoading" v-text="isLoading ? 'Loading...' : 'Load more'"></button>
			</div>
		</div>
	</section>
</template>
<script>
	import {postsApi} from '../../../api';
	export default {
		props: {
			tax: {
				required: true,
				type: String
			}
		},
		data() {
			return {
				page: 1,
				per_page: 19,
				offset: 0,
				total_pages: 0,
				posts: [],
				isLoading: false,
				isFinished: false,
				windowSize: ''
			}
		},
		methods: {
			getPostsByTax() {
				let self = this;
				let args = {
					page: self.page,
					per_page: self.per_page,
					offset: self.offset,
					image_size: 'thumb-small',
					format_date: 'j M Y',
					post_type: 'post, podcasts, webinars, videos'
				}
				self.isLoading = true;
				postsApi.getPostsByTax(self.parseTax.taxonomy, self.parseTax.slug, args).then(response => {
					let data = response.data;
					self.isLoading = false;
					self.total_pages = data.total_pages;
					self.offset = 1;
					self.per_page = 18;
					if(data.total_posts === 0) {
						self.isFinished = true;
					} else {
						self.posts.push.apply(self.posts, data.posts);
						if(self.page === data.total_pages) {
							self.isFinished = true;
						}
						self.page += 1;
					}
				}, error => {
					console.log(error);
				});
			},
			loadMore() {
				let self = this;
				self.getPostsByTax();
			},
			initMounted() {
				let self = this;
				self.getPostsByTax();
			}
		},
		computed: {
			parseTax() {
				let self = this;
				return JSON.parse(self.tax);
			},
			sidePosts() {
				let self = this;
				return self.posts.slice(1, 7);
			},
			allPosts() {
				let self = this;
				return self.windowSize > 960 ? self.posts.slice(7) : self.posts;
			},
			showLoadMore() {
				let self = this;
				return self.total_pages >= 2 ? true : false
			}
		},
		mounted() {
			let self = this;
			self.windowSize = window.innerWidth;
			self.initMounted();
			$(window).on('resize', function() {
				self.windowSize = window.innerWidth;
			});
		}
	}
</script>