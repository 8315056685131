import 'owl.carousel';
import helper from './helpers';
import 'jquery.scrollto';
import 'jquery.localscroll';
import 'jquery-ui-bundle';
import '../node_modules/jquery-timepicker/jquery.timepicker.js';
import {camelCase, upperFirst} from 'lodash';
// scroll header
function ScrollHeader() {
	var scrollTarget = $('#header'),
		scrollClass = 'collapse';
	if(scrollTarget.length) {
		if(helper.isElementScrolledTo(scrollTarget[0].id)) {
			scrollTarget.addClass(scrollClass);
		} else {
			scrollTarget.removeClass(scrollClass);
		}
	}
}
// trigger login
function triggerLoginForm() {
	var $class = 'active',
		$target = $('#login-form'),
		$login = $('.triggerLoginForm');
	$login.on('click', function() {
		$target.addClass($class);
		if($target.hasClass($class)) {
			$target.find('.close').on('click', function() {
				$target.removeClass($class);
			});
		}
		$target.on('click', function(e) {
			if(e.target.id === 'login-form') {
				$target.removeClass($class);
			}
		});
	});
	if(helper.$_GET('login')) {
		setTimeout(function() {
			$login.trigger('click');
		});
	}
}
// trigger modal
function triggerModal(targetname, hideModalOnBgClick = true) {
	var $class = 'active',
		$targetname = targetname,
		$target = $('#' + targetname),
		$bodyClass = 'modal-active modal-' + targetname,
		$modal = $('.triggerModal' + _.upperFirst(_.camelCase(targetname)));
	$modal.on('click', function() {
		$target.addClass($class);
		$('body').addClass($bodyClass);
		if($target.hasClass($class)) {
			$target.find('.modal-close').on('click', function() {
				$target.removeClass($class);
				$('body').removeClass($bodyClass);
			});
		}
		if(hideModalOnBgClick) {
			$target.on('click', function(e) {
				if(e.target.id === targetname) {
					$target.removeClass($class);
					$('body').removeClass($bodyClass);
				}
			});
		}
	});
}
// set cookie policy
function enableCookies() {
	$('#cookie button').on('click', function() {
		helper.setCookie('cookie-accept', true, 365);
		$('#cookie').remove();
	});
}
function activateNavMenu() {
	// mobile nav
	$('#nav-menu').on('click', function() {
		var app = $('#app'),
			activeClass = 'menu-active';
		app.toggleClass(activeClass);
		$(this).toggleClass('fa-times');
	});
	$('#user-menu').on('click', function() {
		var app = $('#app'),
			activeClass = 'menu-active';
		app.toggleClass(activeClass);
		$(this).toggleClass('fa-times');
		$(this).parent().toggleClass('active');
	});
}
// ui toggle
function enableUIToggle() {
	var toggle = $('.ui-toggle'),
		icon = "fa-minus";
	toggle.each(function() {
		var load = $(this).data('toggle');
		if(load == 'show') {
			$(this).find('.ui-toggle-content').css("display", "block");
			$(this).find('.toggleReport').removeClass('fa-plus').addClass(icon);
		}
	});
	$('.ui-toggle-title').on('click', function() {
		$(this).next('.ui-toggle-content').toggle();
		$(this).find('.toggleReport').toggleClass('fa-plus ' + icon);
	});
}
// ui checbox access
function doCheckboxAccess() {
	$('.ui-checkbox input').on('keypress', function(e) {
		e.preventDefault();
		if(e.keyCode === 13) {
			$(this).prop('checked', !$(this).prop('checked'));
		}
	});
}
jQuery(document).ready(function($) {
	$.localScroll({
		duration: 1300,
		offset: -81
	});
	// nav scroll
	ScrollHeader();
	// tabs
	helper.enableTabs();
	// trigger login form
	triggerLoginForm();
	triggerModal('new-report', false);
	// enable cookies
	enableCookies();
	// activate nav menu
	activateNavMenu();
	// ui select
	helper.enableUISelect();
	// ui toggle
	enableUIToggle();
	// checkbox access
	doCheckboxAccess();
	// on scroll
	$(window).on('scroll', function() {
		// if scroll meets end of page, stop scrolling
		if($(window).scrollTop() + $(window).height() != $(document).height()) {
			ScrollHeader();
		}
	});
	// on resize
	$(window).on('resize', function() {
	});
	// sortable
	//$(".list-goals").sortable();
	// client cases arrows
	$('#client-cases .controls span').on('click', function() {
		function scrollArrowShow() {
			var $elem = $('#client-cases .scroll'),
			newScrollLeft = $elem.scrollLeft(),
			width = $elem.width().toFixed(0),
			scrollWidth = $elem[0].scrollWidth,
			offset = (scrollWidth - width - newScrollLeft).toFixed(0);
			if(offset == 0) {
				$('#client-cases .next').removeClass('active').addClass('disabled');
			} else {
				$('#client-cases .prev').removeClass('disabled').addClass('active');
				$('#client-cases .next').removeClass('disabled').addClass('active');
			}
			if(newScrollLeft === 0) {
				$('#client-cases .prev').removeClass('active').addClass('disabled');
			} else {
				$('#client-cases .show').removeClass('disabled').addClass('active');
			}
	    }
		var caseWidth = $('#client-cases .case').width();
		if($(this).hasClass('next')) {
			$('#client-cases .scroll').animate({
				scrollLeft: "+=" + caseWidth + "px"
			}, function() {
				scrollArrowShow();
			});
		} else {
			$('#client-cases .scroll').animate({
				scrollLeft: "-=" + caseWidth + "px"
			}, function() {
				scrollArrowShow();
			});
		}
	});
});