<template>
	<div id="admin-edit-user">
		<div class="col-row">
			<div class="col s12 m12 l8">
				<div class="ui-tabs">
					<div class="ui-tab-controls">
						<span class="ui-tab-btn active" v-if="!isEnquiryNew">Client Details</span>
						<span class="ui-tab-btn" v-if="usermeta.approved">Progress Reports</span>
						<span class="ui-tab-btn" v-if="usermeta.approved">Training Documents</span>
						<span class="ui-tab-btn" v-if="!isEnquiryNew">Goals</span>
						<span class="ui-tab-btn" v-if="!isEnquiryNew">Training</span>
						<span class="ui-tab-btn" v-if="!isEnquiryNew">Nutrition</span>
						<span class="ui-tab-btn" v-if="!isEnquiryNew">Support</span>
					</div>
					<div class="ui-tab-content">
						<div class="ui-tab meta-box">
							<table>
								<tr>
									<td>Firstname</td>
									<td v-text="usermeta.first_name"></td>
								</tr>
								<tr>
									<td>Lastname</td>
									<td v-text="usermeta.last_name"></td>
								</tr>
								<tr>
									<td>Email</td>
									<td v-text="usermeta.email"></td>
								</tr>
								<tr>
									<td>Services required</td>
									<td v-html="implodeServices"></td>
								</tr>
								<tr>
									<td>Phone</td>
									<td v-text="usermeta.phone"></td>
								</tr>
								<tr>
									<td>Received</td>
									<td v-text="usermeta.enquiry_date"></td>
								</tr>
								<tr v-if="isEnquiryNew">
									<td>Newsletter</td>
									<td v-text="usermeta.newsletter"></td>
								</tr>
								<tr v-if="isEnquiryNew">
									<td>Details</td>
									<td v-text="usermeta.additional_details"></td>
								</tr>
								<tr v-if="!isEnquiryNew">
									<td>DOB</td>
									<td v-text="usermeta.dob"></td>
								</tr>
								<tr v-if="!isEnquiryNew">
									<td>Age</td>
									<td v-text="usermeta.age"></td>
								</tr>
								<tr v-if="!isEnquiryNew">
									<td>Height</td>
									<td v-text="usermeta.height_ft && usermeta.height_inches ? usermeta.height_ft + 'ft ' + usermeta.height_inches : ''"></td>
								</tr>
								<tr v-if="!isEnquiryNew">
									<td>Weight</td>
									<td v-text="usermeta.weight_lbs ? usermeta.weight_lbs + 'lbs' : ''"></td>
								</tr>
								<tr v-if="!isEnquiryNew">
									<td>Occupation</td>
									<td v-text="usermeta.occupation"></td>
								</tr>
								<tr v-if="!isEnquiryNew">
									<td>Alcohol</td>
									<td v-text="usermeta.alcohol"></td>
								</tr>
								<tr v-if="!isEnquiryNew">
									<td>Smokes</td>
									<td v-text="usermeta.smoke"></td>
								</tr>
								<tr v-if="!isEnquiryNew">
									<td>Medical conditions</td>
									<td v-text="usermeta.medical_conditions"></td>
								</tr>
								<tr v-if="!isEnquiryNew">
									<td>Current injuries</td>
									<td v-text="usermeta.current_injuries"></td>
								</tr>
							</table>
						</div>
						<div class="ui-tab" v-if="usermeta.approved">
							<user-progress-reports :user_id="user_id" :is_admin_view="true" :list_reports="JSON.stringify(usermeta.progress_reports)" :list_images="JSON.stringify(usermeta.progress_images)"></user-progress-reports>
						</div>
						<div class="ui-tab" v-if="usermeta.approved">
							<ul class="multi-errors" v-if="upload_documents_messages.length">
								<li v-for="message, index in upload_documents_messages" v-text="message" :class="$_.includes(message, 'saved') ? 'success' : 'error'"></li>
							</ul>
							<div id="list-docs" class="col-row">
								<div class="col s6 m6 l3">
									<form id="form-upload-docs" enctype="multipart/form-data">
										<div class="doc">
											<div class="file">
												<span class="fa fa-plus-circle"></span>
											</div>
											<p>Upload a new document</p>
										</div>
										<input type="file" name="files[]" multiple @change="uploadTrainingDocs($event)">
									</form>
								</div>
								<div class="col s6 m6 l3" v-for="doc, index in usermeta.training_documents" v-if="hasTrainingDocs">
									<div class="doc">
										<div class="file">
											<span :class="'fa fa-file' + getFontAwesomeIcon(doc.doc_type)"></span>
										</div>
										<p v-text="doc.doc_title"></p>
									</div>
								</div>
							</div>
						</div>
						<div class="ui-tab meta-box" v-if="!isEnquiryNew">
							<table>
								<tr>
									<td>Goals</td>
									<td>
										<ul v-if="$_.size(usermeta.goals)">
											<li v-for="goalIdxValue, goalKey in usermeta.goals" v-text="goalIdxValue + '. ' + getGoalLabel(goalKey)"></li>
										</ul>
									</td>
								</tr>
								<tr>
									<td>Other goals</td>
									<td v-text="usermeta.other_goals"></td>
								</tr>
								<tr>
									<td>Short term goals</td>
									<td v-text="usermeta.short_term_goals"></td>
								</tr>
								<tr>
									<td>Long term goals</td>
									<td v-text="usermeta.long_term_goals"></td>
								</tr>
								<tr>
									<td>Goal barriers</td>
									<td v-text="usermeta.goal_barriers"></td>
								</tr>
							</table>
						</div>
						<div class="ui-tab meta-box" v-if="!isEnquiryNew">
							<table>
								<tr>
									<td>Weekly exercise lifestyle</td>
									<td v-text="usermeta.weekly_exercise_lifestyle"></td>
								</tr>
								<tr>
									<td>Elaborate exercise</td>
									<td v-text="usermeta.elaborate_exercise"></td>
								</tr>
								<tr>
									<td>Average week lifestyle</td>
									<td v-text="usermeta.average_week_lifestyle"></td>
								</tr>
							</table>
						</div>
						<div class="ui-tab meta-box" v-if="!isEnquiryNew">
							<table>
								<tr>
									<td>Average nutritional intake</td>
									<td v-text="usermeta.average_nutritional_intake"></td>
								</tr>
								<tr>
									<td>Food allergies intolerances</td>
									<td v-text="usermeta.food_allergies_intolerances"></td>
								</tr>
								<tr>
									<td>Water perday</td>
									<td v-text="usermeta.water_perday"></td>
								</tr>
							</table>
						</div>
						<div class="ui-tab meta-box" v-if="!isEnquiryNew">
							<table>
								<tr>
									<td>Support my goals</td>
									<td v-text="usermeta.support_my_goals"></td>
								</tr>
								<tr>
									<td>Package budget</td>
									<td v-text="usermeta.package_budget"></td>
								</tr>
								<tr>
									<td>Hear about us</td>
									<td v-text="usermeta.hear_about_us"></td>
								</tr>
								<tr>
									<td>Provide testimonial</td>
									<td v-text="usermeta.provide_testimonial"></td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="col s12 m12 l4">
				<aside>
					<h4>Actions</h4>
					<div id="payment-request-meta" class="meta-box" v-if="usermeta.service_list_sent">
						<h4 class="meta-title">User Plan &amp; Payment Request</h4>
						<div class="checkbox">
							<label>One-off pre-payment <input type="checkbox" v-model="subscription.is_one_off" :disabled="usermeta.subscription_active"></label>
						</div>
						<div class="checkbox">
							<label>Enable trial period <input type="checkbox" v-model="subscription.is_trial" :disabled="usermeta.subscription_active"></label>
						</div>
						<input v-if="subscription.is_one_off" type="number" min="0" step="10" v-model="subscription.one_off_price" placeholder="Enter one-off payment" :disabled="usermeta.subscription_active">
						<select if="list_products.length" v-model="subscription.plan_id" :disabled="usermeta.subscription_active">
							<option value="" disabled selected>- Select Plan -</option>
							<optgroup v-for="product, productId in list_products" :label="product.name">
								<option :disabled="usermeta.subscription_active" v-if="product.plans.length" v-for="plan, planIndex in product.plans" :value="plan.id" :data-price="plan.amount" :data-name="product.name" :data-interval="plan.interval" v-text="product.name + ' | ' + plan.amount + ' /' + plan.interval"></option>
							</optgroup>
						</select>
						<div class="custom-date" v-if="subscription.is_trial">
							<!-- <label>Select billing start date <input type="date" :min="$moment().format('YYYY-MM-DD')" v-model="subscription.custom_billing_start_date"></label> -->
							<label>Select trial period in weeks (billing will start after this period) <input type="number" :min="1" max="12" v-model="subscription.trial_weeks" :disabled="usermeta.subscription_active"></label>
						</div>
						<button type="button" v-if="!usermeta.subscription_active" @click="sendStripePaymentRequest()" class="ui-btn" :disabled="is_sending_stripe_payment || !subscription.plan_id" v-text="is_sending_stripe_payment ? 'Creating &amp; Sending...' : 'Create &amp; Send Stripe Payment'"></button>
					</div>
					<div class="meta-box" v-show="usermeta.called_back && !usermeta.service_list_sent">
						<h4 class="meta-title">Services required</h4>
						<div id="sendServiceList" class="form-block">
							<div class="form-label">
								<div class="ui-select">
									<span class="select-label" v-html="implodeServices || 'Select service(s)'"></span>
									<div class="select-dropdown">
										<div class="item" v-for="service, index in $lists.services" v-if="$lists.services.length">
											<div class="ui-checkbox">
												<label>
													<input type="checkbox" :value="service.post_title" v-model="usermeta.services_required" />
													<span class="checkbox"></span>
													<span v-text="service.post_title"></span>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<button type="button" @click="sendServiceList()" class="ui-btn" :disabled="service_list_sent || is_sending_service_list" v-text="is_sending_service_list ? 'Sending...' : 'Send service list'"></button>
					</div>
					<div class="meta-box" v-if="usermeta.pre_consultation_complete && !usermeta.called_back && !usermeta.approved">
						<h4 class="meta-title">Callback</h4>
						<p v-text="$moment(usermeta.called_back_datetime).format('dddd Do MMMM YYYY')"></p>
						<p>at</p>
						<p v-text="$moment(usermeta.called_back_datetime).format('HH:mm')"></p>
						<button type="button" @click="markCalledBack()" class="ui-btn" :disabled="usermeta.called_back || is_marking_called_back" v-text="is_marking_called_back ? 'Marking as called back...' : 'Mark as called back'"></button>
					</div>
					<div class="meta-box" v-if="!usermeta.pre_consultation_complete && !usermeta.approved">
						<button type="button" @click="sendPreConsultationForm()" class="ui-btn" :disabled="(usermeta.pre_consultation_sent) || (is_sending_pre_consultation_form)" v-text="is_sending_pre_consultation_form ? 'Sending...' : 'Send Pre-consultation form'"></button>
					</div>
					<div class="meta-box" v-if="isEnquiryClient || isEnquiryConsultation">
						<button type="button" @click="deleteEnquiry()" class="ui-btn ui-btn-outline" v-if="!usermeta.subscription_active" :disabled="is_deleting_enquiry" v-text="is_deleting_enquiry ? 'Deleting...' : usermeta.approved ? 'Delete user' : 'Delete enquiry'"></button>
						<button type="button" @click="cancelSubscription()" class="ui-btn ui-btn-outline" v-if="usermeta.subscription_active" :disabled="is_cancelling_subscription" v-text="is_cancelling_subscription ? 'Cancelling subscription...' : 'Cancel subscription'"></button>
						<p v-if="usermeta.subscription_active"><strong><a :href="$app.site_url + '/terms-of-service?user_id=' + user_id" target="_blank">User Terms of Service</a></strong><br> (link is only available if {{usermeta.first_name}} is logged in and please remove <strong>"?user_id={{user_id}}"</strong> when sending to {{usermeta.first_name}}</p>
					</div>
				</aside>
			</div>
		</div>
	</div>
</template>
<script>
	import {adminApi} from '../../../api';
	export default {
		props: {
			user_meta: {
				required: true
			},
			products: {
				required: true
			},
			user_id: {
				required: true,
				type: Number
			}
		},
		data() {
			return {
				list_products: "",
				upload_documents_messages: [],
				is_sending_pre_consultation_form: false,
				is_deleting_enquiry: false,
				is_marking_called_back: false,
				is_sending_stripe_payment: false,
				is_cancelling_subscription: false,
				is_sending_service_list: false,
				usermeta: {
					goals: {},
					service_list_sent: false
				},
				subscription: {
					plan_id: "",
					plan_name: "",
					plan_price: "",
					plan_interval: "",
					is_one_off: false,
					one_off_price: "",
					is_trial: false,
					trial_weeks: 12
				}
			}
		},
		methods: {
			getGoalLabel(key) {
				let self = this,
					goals = self.$lists.questions.goals,
					goal = self.$_.filter(goals, {'key' : key});
				if(self.$_.size(goal)) {
					return goal[0].label;
				}
			},
			getFontAwesomeIcon(type) {
				let icon = null;
				switch(type) {
					case 'pdf':
						icon = '-pdf';
						break;
					case 'doc':
						icon = '-word';
						break;
					case 'docx':
						icon = '-word';
						break;
					case 'xls':
						icon = '-excel';
						break;
					case 'xlsx':
						icon = '-excel';
						break;
					default:
						icon = '';
						break;
				}
				return icon;
			},
			sendServiceList() {
				let self = this;
				self.is_sending_service_list = true;
				adminApi.sendServiceList(self.user_id, {
					services: self.usermeta.services_required
				}).then(res => {
					if(!res.data.error) {
						alert(res.data.message);
						self.usermeta.service_list_sent = true;
					}
				}, error => {
					self.is_sending_service_list = false;
					alert('Failed');
					console.log(error);
				});
			},
			sendPreConsultationForm() {
				let self = this;
				self.is_sending_pre_consultation_form = true;
				adminApi.sendPreConsultationForm(self.user_id).then(res => {
					if(!res.data.error) {
						alert(res.data.message);
						window.location.href = self.$app.site_url + '/admin/pre-consultation-callbacks?user=' + self.user_id;
					}
				}, error => {
					self.is_sending_pre_consultation_form = false;
					alert('Failed');
					console.log(error);
				});
			},
			deleteEnquiry() {
				let self = this;
				let cf = confirm("Are you sure you want to delete this enquiry?");
				if(cf) {
					self.is_deleting_enquiry = true;
					adminApi.deleteEnquiry(self.user_id).then(res => {
						if(!res.data.error) {
							alert('User has been deleted');
							window.location.reload();
						}
					}, error => {
						self.is_deleting_enquiry = false;
						alert('Failed');
						console.log(error);
					});
				}
			},
			markCalledBack() {
				let self = this;
				self.is_marking_called_back = true;
				adminApi.markCalledBack(self.user_id).then(res => {
					if(!res.data.error) {
						alert(res.data.message);
						self.is_marking_called_back = false;
						self.usermeta.called_back = true;
					}
				}, error => {
					self.is_marking_called_back = false;
					alert('Failed');
					console.log(error);
				});
			},
			createStripePlan() {
				let self = this;
				self.is_sending_stripe_payment = true;
				adminApi.createStripePlan(self.user_id, self.subscription).then(res => {
					if(!res.data.error) {
						self.is_sending_stripe_payment = false;
						alert(res.data.message);
						window.location.reload();
					}
				}, error => {
					self.is_sending_stripe_payment = false;
					alert('Failed');
					console.log(error);
				});
			},
			sendStripePaymentRequest() {
				let self = this;
				if(self.subscription.plan_id) {
					self.is_sending_stripe_payment = true;
					adminApi.sendStripePaymentRequest(self.user_id, self.subscription).then(res => {
						if(!res.data.error) {
							self.is_sending_stripe_payment = false;
							alert('Payment request sent');
						}
					}, error => {
						self.is_sending_stripe_payment = false;
						alert('Failed');
						console.log(error);
					});
				} else {
					alert("Please select a plan");
				}
			},
			cancelSubscription() {
				let self = this;
				let cf = confirm("Are you sure you want to cancel this subscription");
				if(cf) {
					self.is_cancelling_subscription = true;
					adminApi.cancelSubscription(self.user_id).then(res => {
						if(!res.data.error) {
							alert('Subscription has been cancelled');
							window.location.reload();
							/*self.is_cancelling_subscription = false;
							self.usermeta.subscription_active = false;*/
						}
					}, error => {
						self.is_cancelling_subscription = false;
						alert('Failed');
						console.log(error);
					});
				}
			},
			uploadTrainingDocs(event) {
				let self = this,
					files = event.target.files;
				// clear upload notice messages
				self.upload_documents_messages = [];
				if(files.length) {
					for(var i = 0; i < files.length; i++) {
						let file = files[i];
						let data = new FormData();
						data.append('file', file);
						adminApi.uploadTrainingDocs(self.user_id, data).then(res => {
							if(!res.data.error) {
								self.upload_documents_messages.push(res.data.message);
								self.usermeta.training_documents.unshift(res.data.document);
							}
						}, (error) => {
							console.log(error);
							self.upload_documents_messages.push(error.response.data.message);
						});
					}
					// reset form
					$('#form-upload-docs')[0].reset();
				}
			},
			initMounted() {
				let self = this;
				self.usermeta = JSON.parse(self.user_meta);
				self.list_products = JSON.parse(self.products);

				// set active subscription
				if(self.usermeta.subscription_plan) {
					self.subscription = {
						plan_id: self.usermeta.subscription_plan.plan_id,
						plan_name: self.usermeta.subscription_plan.plan_name,
						plan_price: self.usermeta.subscription_plan.plan_price,
						plan_interval: self.usermeta.subscription_plan.plan_interval,
						is_one_off: Boolean(self.usermeta.subscription_plan.is_one_off),
						one_off_price: self.usermeta.subscription_plan.one_off_price,
						is_trial: Boolean(self.usermeta.subscription_plan.is_trial),
						trial_weeks: self.usermeta.subscription_plan.trial_weeks,
					}
				}

				setTimeout(() => {
					$('.ui-tab-btn').on('click', function() {
						if(window.innerWidth > 960) {
							var txt = $(this).text(), adminPanel = $('#admin-edit-user');
							if(txt === 'Progress Reports' || txt === 'Training Documents') {
								adminPanel.find('> .col-row > .col').hide();
								adminPanel.find('> .col-row > .col:first-child').show().removeClass('m8 l8').addClass('m12 l12');
							} else {
								adminPanel.find('> .col-row > .col').show();
								adminPanel.find('> .col-row > .col:first-child').show().removeClass('m12 l12').addClass('m8 l8');
							}
						}
					});
					// on change price filter
					$('#payment-request-meta select').on('change', function() {
						var select = $(this),
							selectedOptionPlan = select.find('option[value="'+ self.subscription.plan_id +'"]');
						console.log(selectedOptionPlan);
						self.subscription.plan_name = selectedOptionPlan.data('name');
						self.subscription.plan_price = selectedOptionPlan.data('price');
						self.subscription.plan_interval = selectedOptionPlan.data('interval');
					});
				}, 500);
			}
		},
		computed: {
			isEnquiryNew() {
				let self = this;
				return self.usermeta.role === 'enquiry';
			},
			isEnquiryConsultation() {
				let self = this;
				return self.usermeta.role === 'pre-consultation';
			},
			isEnquiryClient() {
				let self = this;
				return self.usermeta.role === 'client';
			},
			implodeServices() {
				let self = this;
				if(self.usermeta.services_required) {
					return self.usermeta.services_required.join(', ');
				} else {
					return '';
				}
			},
			hasTrainingDocs() {
				let self = this;
				return self.usermeta.training_documents.length > 0;
			}
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>