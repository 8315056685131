<template>
	<section id="search-results" class="section-spacing">
		<div class="inner">
			<div class="col-row auto-col-clear" v-if="posts.length">
				<div class="col l2 m4 s6" v-for="post, index in posts" :key="`post-${index}`">
					<div class="post-block">
						<figure class="img respond">
							<a :href="post.post_guid">
								<img :src="post.post_image" :alt="post.post_title" />
							</a>
						</figure>
						<div class="content">
							<a v-if="post.post_cat" :href="post.post_cat.slug" class="cat" v-html="post.post_cat.name"></a>
							<h3 class="title">
								<a :href="post.post_guid" v-html="post.post_title"></a>
							</h3>
						</div>
					</div>
				</div>
			</div>
			<div class="view-more ui-text-center section-spacing" v-if="showLoadMore">
				<button type="button" class="ui-btn ui-btn-outline" @click="loadMore" :disabled="isFinished || isLoading" v-text="isLoading ? 'Loading...' : 'Load more'"></button>
			</div>
		</div>
	</section>
</template>
<script>
	import {postsApi} from '../../../api';
	import helper from '../../../helpers';
	export default {
		props: {
			type: {
				required: true,
				type: String
			}
		},
		data() {
			return {
				page: 1,
				per_page: 18,
				total_pages: 0,
				posts: [],
				isLoading: false,
				isFinished: false,
			}
		},
		methods: {
			searchPosts() {
				let self = this;
				let args = {
					page: self.page,
					per_page: self.per_page,
					image_size: 'thumb-small',
					format_date: 'j M Y',
					s: helper.$_GET('s'),
					type: self.type
				}
				self.isLoading = true;
				postsApi.searchPosts(args).then(response => {
					let data = response.data;
					self.isLoading = false;
					self.total_pages = data.total_pages;
					if(data.total_posts === 0) {
						self.isFinished = true;
					} else {
						self.posts.push.apply(self.posts, data.posts);
						if(self.page === data.total_pages) {
							self.isFinished = true;
						}
						self.page += 1;
					}
				}, error => {
					console.log(error);
				});
			},
			loadMore() {
				let self = this;
				self.searchPosts();
			},
			initMounted() {
				let self = this;
				self.searchPosts();
			}
		},
		computed: {
			showLoadMore() {
				let self = this;
				return self.total_pages >= 2 ? true : false
			}
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>