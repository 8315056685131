<template>
	<div id="contact-form">
		<ul class="form-progress clearfix">
			<li v-for="tab, index in tabs" :index="`step-${index}`" :class="{'active': index === formStep, 'complete': formStep > index || consultation_complete === true}">
				<span class="step-label">
					<span class="label" v-text="tab.key"></span>
					<span class="count" v-text="index + 1"></span>
				</span>
			</li>
		</ul>
		<form @submit.prevent="save" novalidate>
			<h3 class="heading-line" v-show="!consultation_complete" v-text="getFormStep.tab"></h3>
			<div class="form-step">
				<div class="col-row">
					<div class="col s12 m6 l6">
						<div class="form-block">
							<label class="form-label required" data-field="firstname">
								<span class="label">Firstname</span>
								<div class="form-field input">
									<input v-model="model.firstname" type="text" class="field">
								</div>
							</label>
						</div>
					</div>
					<div class="col s12 m6 l6">
						<div class="form-block">
							<label class="form-label required" data-field="surname">
								<span class="label">Surname</span>
								<div class="form-field input">
									<input v-model="model.surname" type="text" class="field">
								</div>
							</label>
						</div>
					</div>
				</div>
				<div class="form-block">
					<label class="form-label required" data-field="email">
						<span class="label">Email address</span>
						<div class="form-field input">
							<input v-model="model.email" type="email" maxlength="64" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" class="field" disabled readonly>
						</div>
					</label>
				</div>
				<div class="form-block">
					<label class="form-label required" data-field="phone">
						<span class="label">Phone</span>
						<div class="form-field input">
							<input v-model="model.phone" type="tel" pattern="[0-9]*" minlength="11" maxlength="11" class="field">
						</div>
					</label>
				</div>
				<div class="form-block">
					<label class="form-label required" data-field="occupation">
						<span class="label">Occupation</span>
						<div class="form-field input">
							<input v-model="model.occupation" type="text" class="field">
						</div>
					</label>
				</div>
				<div class="form-block" data-field="DOB">
					<p>Date of birth</p>
					<div class="col-row">
						<div class="col s4 m4 l4">
							<div class="form-block">
								<label class="form-label required">
									<span class="label">DD</span>
									<div class="form-field select">
										<select class="field" v-model="model.dob.dd">
											<option value="" selected disabled>- Day -</option>
											<option v-for="day in dob.days()" :value:number="day" v-text="day"></option>
										</select>
									</div>
								</label>
							</div>
						</div>
						<div class="col s4 m4 l4">
							<div class="form-block">
								<label class="form-label required">
									<span class="label">MM</span>
									<div class="form-field select">
										<select class="field" v-model="model.dob.mm">
											<option value="" selected disabled>- Month -</option>
											<option v-for="month in dob.months()" :value="month.id" v-text="month.name"></option>
										</select>
									</div>
								</label>
							</div>
						</div>
						<div class="col s4 m4 l4">
							<div class="form-block">
								<label class="form-label required">
									<span class="label">YYYY</span>
									<div class="form-field select">
										<select class="field" v-model="model.dob.yy">
											<option value="" selected disabled>- Year -</option>
											<option v-for="year in dob.years(100, 18)" :value:number="year" v-text="year"></option>
										</select>
									</div>
								</label>
							</div>
						</div>
					</div>
				</div>
				<div class="form-block">
					<div class="col-row">
						<div class="col s12 m12 l7">
							<span>Height</span>
							<div class="col-row">
								<div class="col s6 m6 l6">
									<div class="form-block">
										<label class="form-label required" data-field="height_ft">
											<span class="label">Feet</span>
											<div class="form-field select">
												<select class="field" v-model="model.height.ft">
													<option value="" selected disabled>- Select -</option>
													<option v-for="ft in $_.range(3, 8)" :value:number="ft" v-text="ft"></option>
												</select>
											</div>
										</label>
									</div>
								</div>
								<div class="col s6 m6 l6">
									<div class="form-block">
										<label class="form-label required" data-field="height_inches">
											<span class="label">Inches</span>
											<div class="form-field select">
												<select class="field" v-model="model.height.inches">
													<option value="" selected disabled>- Select -</option>
													<option v-for="inch in 12" :value:number="inch" v-text="inch"></option>
												</select>
											</div>
										</label>
									</div>
								</div>
							</div>
						</div>
						<div class="col s12 m12 l5">
							<span>Weight</span>
							<div class="col-row">
								<div class="col s12 m12 l12">
									<div class="form-block">
										<label class="form-label required" data-field="weight_lbs">
											<span class="label">Lbs</span>
											<div class="form-field input">
												<input v-model="model.weight_lbs" type="number" class="field" min="1">
											</div>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-block">
					<fieldset class="form-label required" data-field="alcohol">
						<span class="label">Do you drink alcohol?</span>
						<div class="ui-radio">
							<label>
								<input type="radio" v-model="model.alcohol" value="Yes" />
								<span class="radio"></span>
								<span>Yes</span>
							</label>
						</div>
						<div class="ui-radio">
							<label>
								<input type="radio" v-model="model.alcohol" value="No" />
								<span class="radio"></span>
								<span>No</span>
							</label>
						</div>
					</fieldset>
				</div>
				<div class="form-block">
					<fieldset class="form-label required" data-field="smoke">
						<span class="label">Do you smoke?</span>
						<div class="ui-radio">
							<label>
								<input type="radio" v-model="model.smoke" value="Yes" />
								<span class="radio"></span>
								<span>Yes</span>
							</label>
						</div>
						<div class="ui-radio">
							<label>
								<input type="radio" v-model="model.smoke" value="No" />
								<span class="radio"></span>
								<span>No</span>
							</label>
						</div>
					</fieldset>
				</div>
				<div class="form-block">
					<label class="form-label required" data-field="medical_conditions">
						<span class="label" v-text="getQuestionByKey('medical_conditions')"></span>
						<div class="form-field textarea">
							<textarea v-model="model.medical_conditions" class="field"></textarea>
						</div>
					</label>
				</div>
				<div class="form-block">
					<label class="form-label required" data-field="current_injuries">
						<span class="label" v-text="getQuestionByKey('current_injuries')"></span>
						<div class="form-field textarea">
							<textarea v-model="model.current_injuries" class="field"></textarea>
						</div>
					</label>
				</div>
			</div>
			<div class="form-step">
				<div class="form-block">
					<div class="form-label required" data-field="goals">
						<span class="label">Please number these statements from 1 to {{list.goals.length}} in order of importance to you, where 1 = most important</span>
						<ul class="list-goals" data-handle=".form-block">
							<li class="goal" v-for="goal, index in list.goals" data-key="goal.key" :index="`goal-${index}`">
								<select v-model="model.goals[goal.key]">
									<option v-for="goalOption, indexOption in list.goals" :disabled="$_.includes($_.values(model.goals), indexOption+1)" :value="indexOption+1" v-text="indexOption+1"></option>
								</select>
								{{goal.label}}
							</li>
						</ul>
					</div>
				</div>
				<p>In order to get the most out of your plan, you need to measure how long these goals "should" take. Please categorise your goals below:</p>
				<div class="form-block">
					<label class="form-label required" data-field="other_goals">
						<span class="label" v-text="getQuestionByKey('other_goals')"></span>
						<div class="form-field textarea">
							<textarea v-model="model.other_goals" class="field"></textarea>
						</div>
					</label>
				</div>
				<div class="form-block">
					<label class="form-label required" data-field="short_term_goals">
						<span class="label" v-text="getQuestionByKey('short_term_goals')"></span>
						<div class="form-field textarea">
							<textarea v-model="model.short_term_goals" class="field"></textarea>
						</div>
					</label>
				</div>
				<div class="form-block">
					<label class="form-label required" data-field="long_term_goals">
						<span class="label" v-text="getQuestionByKey('long_term_goals')"></span>
						<div class="form-field textarea">
							<textarea v-model="model.long_term_goals" class="field"></textarea>
						</div>
					</label>
				</div>
				<div class="form-block">
					<label class="form-label required" data-field="goal_barriers">
						<span class="label" v-text="getQuestionByKey('goal_barriers')"></span>
						<div class="form-field textarea">
							<textarea v-model="model.goal_barriers" class="field"></textarea>
						</div>
					</label>
				</div>
			</div>
			<div class="form-step">
				<div class="form-block">
					<label class="form-label required" data-field="weekly_exercise_lifestyle">
						<span class="label" v-text="getQuestionByKey('weekly_exercise_lifestyle')"></span>
						<div class="form-field textarea">
							<textarea v-model="model.weekly_exercise_lifestyle" class="field"></textarea>
						</div>
					</label>
				</div>
				<div class="form-block">
					<label class="form-label required" data-field="elaborate_exercise">
						<span class="label" v-text="getQuestionByKey('elaborate_exercise')"></span>
						<div class="form-field textarea">
							<textarea v-model="model.elaborate_exercise" class="field"></textarea>
						</div>
					</label>
				</div>
				<div class="form-block">
					<label class="form-label required" data-field="average_week_lifestyle">
						<span class="label" v-text="getQuestionByKey('average_week_lifestyle')"></span>
						<div class="form-field textarea">
							<textarea v-model="model.average_week_lifestyle" class="field"></textarea>
						</div>
					</label>
				</div>
			</div>
			<div class="form-step">
				<div class="form-block">
					<label class="form-label required" data-field="average_nutritional_intake">
						<span class="label" v-text="getQuestionByKey('average_nutritional_intake')"></span>
						<div class="form-field textarea">
							<textarea v-model="model.average_nutritional_intake" class="field"></textarea>
						</div>
					</label>
				</div>
				<div class="form-block">
					<label class="form-label required" data-field="food_allergies_intolerances">
						<span class="label" v-text="getQuestionByKey('food_allergies_intolerances')"></span>
						<div class="form-field textarea">
							<textarea v-model="model.food_allergies_intolerances" class="field"></textarea>
						</div>
					</label>
				</div>
				<div class="form-block">
					<label class="form-label required" data-field="water_perday">
						<span class="label" v-text="getQuestionByKey('water_perday')"></span>
						<div class="form-field textarea">
							<textarea v-model="model.water_perday" class="field"></textarea>
						</div>
					</label>
				</div>
			</div>
			<div class="form-step">
				<div class="form-block">
					<label class="form-label required" data-field="support_my_goals">
						<span class="label" v-text="getQuestionByKey('support_my_goals')"></span>
						<div class="form-field textarea">
							<textarea v-model="model.support_my_goals" class="field"></textarea>
						</div>
					</label>
				</div>
				<div class="form-block">
					<label class="form-label required" data-field="package_budget">
						<span class="label" v-text="getQuestionByKey('package_budget')"></span>
						<div class="form-field textarea">
							<textarea v-model="model.package_budget" class="field"></textarea>
						</div>
					</label>
				</div>
				<div class="form-block">
					<label class="form-label required" data-field="hear_about_us">
						<span class="label" v-text="getQuestionByKey('hear_about_us')"></span>
						<div class="form-field textarea">
							<textarea v-model="model.hear_about_us" class="field"></textarea>
						</div>
					</label>
				</div>
				<div class="form-block">
					<label class="form-label required" data-field="provide_testimonial">
						<span class="label" v-text="getQuestionByKey('provide_testimonial')"></span>
						<div class="form-field textarea">
							<textarea v-model="model.provide_testimonial" class="field"></textarea>
						</div>
					</label>
				</div>
			</div>
			<div class="form-step">
				<div class="col-row">
					<div class="col s12 m6 l6">
						<div class="form-block">
							<label class="form-label required" data-field="callback_date">
								<span class="label">Date</span>
								<div class="form-field input">
									<input type="text" class="datepicker field" readonly>
								</div>
							</label>
						</div>
					</div>
					<div class="col s12 m6 l6">
						<div class="form-block">
							<div class="form-block">
								<label class="form-label required" data-field="callback_time">
									<span class="label">Time</span>
									<div class="form-field input">
										<input type="text" class="timepicker field" readonly>
									</div>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="complete" v-show="consultation_complete">
				<h3 class="heading-line">Form and callback request submitted</h3>
				<p>Thank you for completing the pre-consultation form.</p>
				<h3 class="heading-line">Next steps</h3>
				<p>One of our team members will review your details within 24 hours and call you on <strong v-text="model.phone"></strong> on <strong v-text="$moment(model.callback_date, 'DD-MM-YYYY').format('DD/MM/YYYY')"></strong> to discuss your requirements in detail.</p>
				<p><strong>We look forward to working together with you, speak soon!</strong></p>
				<h2>Adrian Ball</h2>
				<h3>Director, {{$app.name}}</h3>
				<p>In the mean time why not check out some of our informative videos on our social media platforms.</p>
				<a href="https://www.instagram.com/phoenix_physiques" class="ui-btn ui-btn-block btn-arrow-right">View Videos</a>
			</div>
			<button type="button" class="ui-btn ui-btn-block btn-arrow-right" v-if="!isStepEnd && !consultation_complete" v-text="getFormStep.key" @click="change('next')"></button>
			<button type="submit" class="ui-btn ui-btn-block btn-arrow-right" :disabled="is_submitting_consultation" v-text="is_submitting_consultation ? 'Submitting...' : 'Submit form + request callback'" v-if="isStepEnd && !consultation_complete"></button>
			<button type="button" class="prev btn-arrow-left" v-if="!isStepFirst && !consultation_complete" v-text="tabs[formStep - 1].key" @click="change('prev')"></button>
		</form>
	</div>
</template>
<script>
	import {userApi} from '../../../api';
	import helper from '../../../helpers';
	export default {
		props: {
			token: {
				required: true
			},
			user: {
				required: true,
			}
		},
		data() {
			return {
				dob: {
					days() {
						return helper.days();
					},
					months() {
						return helper.months;
					},
					years(amount, years) {
						return helper.years(amount, years);
					}
				},
				consultation_complete: false,
				is_submitting_consultation: false,
				formStep: 0,
				tabs: [
					{
						tab: "My Details",
						key: "details"
					},
					{
						tab: "My Goals",
						key: "goals"
					},
					{
						tab: "My Training & Lifestyle",
						key: "training"
					},
					{
						tab: "My Nutrition",
						key: "nutrition"
					},
					{
						tab: "Support",
						key: "support"
					},
					{
						tab: "Callback Request",
						key: "callback"
					},
				],
				model: {
					goals: {},
					height: {
						ft: '',
						inches: ''
					},
					dob: {
						dd: '',
						mm: '',
						yy: ''
					}
				},
				list: {
					goals: {}
				}
			}
		},
		methods: {
			setFormStep(i) {
				let self = this;
				self.formStep = i;
				$('.form-step').hide().eq(self.formStep).show();
				window.scroll(0, 0);
			},
			change(direction = 'next') {
				let self = this;
				if(direction === 'next') {
					self.setFormStep(self.formStep + 1);
				}
				if(direction === 'prev') {
					self.setFormStep(self.formStep - 1);
				}
			},
			save() {
				let self = this;
				self.is_submitting_consultation = true;
				$('.error').remove();
				userApi.submitPreConsultation(self.model).then(res => {
					if(!res.data.error) {
						$('.form-step').remove();
						self.consultation_complete = true;
						self.is_submitting_consultation = false;
					}
				}, (error) => {
					let errors = error.response.data;
					console.log(errors);
					if(errors.errors) {
						Object.entries(errors.errors).forEach((value, index) => {
							$('[data-field="' + value[0] + '"]').append('<span class="error">' + value[1] + '</span>');
						});
						var idx = $('[data-field="' + Object.keys(errors.errors)[0] + '"]').parents('.form-step').index();
						self.setFormStep(idx - 1);
					}
					self.is_submitting_consultation = false;
				});
			},
			getQuestionByKey(key) {
				let self = this;
				if(key) {
					return self.$lists.questions.consultation[key];
				}
			},
			initMounted() {
				let self = this, user = JSON.parse(self.user);
				self.model.token = self.token;
				self.model.firstname = user.firstname;
				self.model.surname = user.surname;
				self.model.email = user.email;
				self.model.phone = user.phone;
				self.list.goals = self.$lists.questions.goals;
				self.list.goals.forEach((goal, i) => {
					self.model.goals[goal.key] = 0;
				});
				$(".datepicker").datepicker({
					firstDay: 1,
					minDate: 1,
					dateFormat: 'dd-mm-yy',
					beforeShowDay: $.datepicker.noWeekends,
					onSelect: function(date) {
						self.model.callback_date = date;
					},
				});
				$('.timepicker').timepicker({
					timeFormat: 'HH:mm',
					interval: 30,
					minTime: '10',
					maxTime: '17:30pm',
					defaultTime: '11',
					startTime: '9:00am',
					dynamic: false,
					dropdown: true,
					scrollbar: true,
					change: function(time) {
						var el = $('.timepicker');
						if(el.val()) {
							self.model.callback_time = el.val();
						}
					}
				});
				$('.timepicker').val('');
				self.model.callback_time = "";
			}
		},
		computed: {
			getFormStep() {
				let self = this;
				return self.tabs[self.formStep];
			},
			isStepFirst() {
				let self = this;
				return self.formStep === 0;
			},
			isStepEnd() {
				let self = this;
				return self.formStep === (self.tabs.length - 1);
			}
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>