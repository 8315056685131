<template>
	<div id="progress-reports">
		<div id="list-images" v-if="is_admin_view && images.length">
			<figure v-for="image in images" :style="'background-image: url(' + encodeURI(image.image_url) + ')'">
			</figure>
		</div>
		<a href="javascript:void(0);" class="triggerModalNewReport ui-btn" v-if="!is_admin_view">Create new progress report</a>
		<div id="new-report" class="modal-wrapper" v-if="initNewReportModal">
			<div class="modal">
				<button class="modal-close" v-if="!report_completed">
					<span class="fa fa-times"></span>
				</button>
				<h3 class="modal-title heading-line center" v-text="report_completed ? 'Thank you' : 'New progress report'"></h3>
				<p v-html="report_completed ? 'Your progress report for <strong>' + $moment().format('DD MMM YYYY') + '</strong> has successfully been added' : '<time>' + $moment().format('DD MMM YYYY') + '</time>'"></p>
				<form @submit.prevent="newReport()" enctype="multipart/form-data" v-if="!report_completed">
					<div class="desc">
						<p>To help keep you accountable and track your progress, you are encouraged to fill out this form every week. Be as reflective and as honest as you can so that we can work together to overcome any obstacles and make changes if necessary.</p>
					</div>
					<h4 class="heading-line">Weight</h4>
					<div class="form-block">
						<div class="col-row">
							<div class="col s6 m6 l6">
								<label class="form-label" data-field="previous_weight_lbs" data-type="weight">
									<span class="label" v-text="getQuestionByKey('weight', 'previous_weight_lbs')"></span>
									<div class="form-field input">
										<input v-model="model.weight.previous_weight_lbs" type="number" class="field" min="1" step="any">
									</div>
								</label>
							</div>
							<div class="col s6 m6 l6">
								<label class="form-label" data-field="current_weight_lbs" data-type="weight">
									<span class="label" v-text="getQuestionByKey('weight', 'current_weight_lbs')"></span>
									<div class="form-field input">
										<input v-model="model.weight.current_weight_lbs" type="number" class="field" min="1" step="any">
									</div>
								</label>
							</div>
						</div>
					</div>
					<h4 class="heading-line">Sleep</h4>
					<div class="form-block">
						<label class="form-label" data-field="daily_hours_sleep" data-type="sleep">
							<span class="label" v-text="getQuestionByKey('sleep', 'daily_hours_sleep')"></span>
							<div class="form-field input">
								<input v-model="model.sleep.daily_hours_sleep" type="text" class="field">
							</div>
						</label>
					</div>
					<h4 class="heading-line">Water</h4>
					<div class="form-block">
						<label class="form-label" data-field="daily_litres_consumed" data-type="water">
							<span class="label" v-text="getQuestionByKey('water', 'daily_litres_consumed')"></span>
							<div class="form-field input">
								<input v-model="model.water.daily_litres_consumed" type="number" class="field" min="0" step="any">
							</div>
						</label>
					</div>
					<h4 class="heading-line">Monthly cycle</h4>
					<div class="form-block">
						<label class="form-label" data-field="ongoing_time_weigh_in" data-type="monthly_cycle">
							<span class="label" v-text="getQuestionByKey('monthly_cycle', 'ongoing_time_weigh_in')"></span>
							<div class="form-field input">
								<input v-model="model.monthly_cycle.ongoing_time_weigh_in" type="text" class="field">
							</div>
						</label>
					</div>
					<div class="form-block">
						<label class="form-label" data-field="affected_training_nutrition" data-type="monthly_cycle">
							<span class="label" v-text="getQuestionByKey('monthly_cycle', 'affected_training_nutrition')"></span>
							<div class="form-field input">
								<input v-model="model.monthly_cycle.affected_training_nutrition" type="text" class="field">
							</div>
						</label>
					</div>
					<h4 class="heading-line">Resistance</h4>
					<div class="form-block">
						<label class="form-label" data-field="sessions_completed" data-type="resistance">
							<span class="label" v-text="getQuestionByKey('resistance', 'sessions_completed')"></span>
							<div class="form-field input">
								<input v-model="model.resistance.sessions_completed" type="text" class="field">
							</div>
						</label>
					</div>
					<div class="form-block">
						<label class="form-label" data-field="trained_before_weigh_in" data-type="resistance">
							<span class="label" v-text="getQuestionByKey('resistance', 'trained_before_weigh_in')"></span>
							<div class="form-field input">
								<input v-model="model.resistance.trained_before_weigh_in" type="text" class="field">
							</div>
						</label>
					</div>
					<h4 class="heading-line">Cardio</h4>
					<div class="form-block">
						<label class="form-label" data-field="sessions_completed" data-type="cardio">
							<span class="label" v-text="getQuestionByKey('cardio', 'sessions_completed')"></span>
							<div class="form-field input">
								<input v-model="model.cardio.sessions_completed" type="text" class="field">
							</div>
						</label>
					</div>
					<div class="form-block">
						<label class="form-label" data-field="duration_mins_per_session" data-type="cardio">
							<span class="label" v-text="getQuestionByKey('cardio', 'duration_mins_per_session')"></span>
							<div class="form-field input">
								<input v-model="model.cardio.duration_mins_per_session" type="number" class="field" min="0">
							</div>
						</label>
					</div>
					<div class="form-block">
						<label class="form-label" data-field="cardio_excerise_completed" data-type="cardio">
							<span class="label" v-text="getQuestionByKey('cardio', 'cardio_excerise_completed')"></span>
							<div class="form-field input">
								<input v-model="model.cardio.cardio_excerise_completed" type="text" class="field">
							</div>
						</label>
					</div>
					<div class="form-block">
						<label class="form-label" data-field="average_week_step" data-type="cardio">
							<span class="label" v-text="getQuestionByKey('cardio', 'average_week_step')"></span>
							<div class="form-field input">
								<input v-model="model.cardio.average_week_step" type="text" class="field">
							</div>
						</label>
					</div>
					<div class="form-block">
						<label class="form-label" data-field="speed_inline_level_difficulty" data-type="cardio">
							<span class="label" v-text="getQuestionByKey('cardio', 'speed_inline_level_difficulty')"></span>
							<div class="form-field input">
								<input v-model="model.cardio.speed_inline_level_difficulty" type="text" class="field">
							</div>
						</label>
					</div>
					<div class="form-block">
						<label class="form-label" data-field="barrier_challenges" data-type="cardio">
							<span class="label" v-text="getQuestionByKey('cardio', 'barrier_challenges')"></span>
							<div class="form-field textarea">
								<textarea v-model="model.cardio.barrier_challenges" class="field"></textarea>
							</div>
						</label>
					</div>
					<div class="form-block">
						<label class="form-label" data-field="self_improvement_reflection" data-type="cardio">
							<span class="label" v-text="getQuestionByKey('cardio', 'self_improvement_reflection')"></span>
							<div class="form-field textarea">
								<textarea v-model="model.cardio.self_improvement_reflection" class="field"></textarea>
							</div>
						</label>
					</div>
					<h4 class="heading-line">Nutrition</h4>
					<div class="form-block">
						<label class="form-label" data-field="last_off_plan_meal" data-type="nutrition">
							<span class="label" v-text="getQuestionByKey('nutrition', 'last_off_plan_meal')"></span>
							<div class="form-field input">
								<input v-model="model.nutrition.last_off_plan_meal" type="text" class="field">
							</div>
						</label>
					</div>
					<div class="form-block">
						<label class="form-label" data-field="digestion_concerns" data-type="nutrition">
							<span class="label" v-text="getQuestionByKey('nutrition', 'digestion_concerns')"></span>
							<div class="form-field input">
								<input v-model="model.nutrition.digestion_concerns" type="text" class="field">
							</div>
						</label>
					</div>
					<div class="form-block">
						<label class="form-label" data-field="sticking_to_eating_routine" data-type="nutrition">
							<span class="label" v-text="getQuestionByKey('nutrition', 'sticking_to_eating_routine')"></span>
							<div class="form-field textarea">
								<textarea v-model="model.nutrition.sticking_to_eating_routine" class="field"></textarea>
							</div>
						</label>
					</div>
					<h4 class="heading-line">Training</h4>
					<div class="form-block">
						<label class="form-label" data-field="note_my_achievements" data-type="training">
							<span class="label" v-text="getQuestionByKey('training', 'note_my_achievements')"></span>
							<div class="form-field textarea">
								<textarea v-model="model.training.note_my_achievements" class="field"></textarea>
							</div>
						</label>
					</div>
					<h4 class="heading-line">Summary</h4>
					<div class="form-block">
						<label class="form-label" data-field="brief_desc_of_plan" data-type="summary">
							<span class="label" v-text="getQuestionByKey('summary', 'brief_desc_of_plan')"></span>
							<div class="form-field textarea">
								<textarea v-model="model.summary.brief_desc_of_plan" class="field"></textarea>
							</div>
						</label>
					</div>
					<div class="form-block">
						<label class="form-label" data-field="coach_desc" data-type="summary">
							<span class="label" v-text="getQuestionByKey('summary', 'coach_desc')"></span>
							<div class="form-field textarea">
								<textarea v-model="model.summary.coach_desc" class="field"></textarea>
							</div>
						</label>
					</div>
					<h4 class="heading-line">Progress Reports</h4>
					<div class="form-block">
						<div class="list-ratings col-row auto-col-clear">
							<div class="col s6 m6 l6" v-for="(label, key) in getQuestionByKey('progress_ratings')">
								<div class="rating-wrap" :data-field="key" data-type="progress_ratings">
									<div class="rating">
										<p class="title" v-text="label"></p>
										<div class="radio" v-for="(option, optionIndex) in $lists.questions.ratings">
											<label>
												<input type="radio" v-model="model.progress_ratings[key]" :value="option">
												{{ optionIndex + 1 }} - {{ option }}
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<h4 class="heading-line">Photo upload</h4>
					<div class="form-block" data-field="image_id" data-type="photo_upload">
						<p>Select a photo to upload</p>
						<button type="button" class="uploadPhoto ui-btn ui-btn-block">
							<span class="fa fa-plus"></span> {{ is_processing_image ? 'Uploading image...' : 'Upload Image' }}
							<input type="file" name="file" accept="image/*" @change="uploadPhoto($event)">
						</button>
						<p><small>{{ imagename ? imagename + ' ' : 'No image selected' }} - Supported file formats JPG, PNG</small></p>
					</div>
					<button type="submit" class="ui-btn ui-btn-block" :disabled="is_submitting_report" v-text="is_submitting_report ? 'Submitting...' : 'Submit progress report'"></button>
				</form>
			</div>
		</div>
		<div id="list-reports" v-if="hasReports">
			<div class="report ui-toggle" v-for="(report, reportIndex) in reports" :data-toggle="reportIndex === 0 ? 'show' : 'hide'">
				<div class="heading ui-toggle-title">
					<h3 v-text="$moment(report.date).format('DD MMM YYYY')"></h3>
					<button type="button" class="icon fas fa-trash deleteReport" v-if="is_admin_view" @click="deleteReport(report, reportIndex)" :disabled="report.deleting"></button>
					<span class="icon fas fa-plus toggleReport"></span>
				</div>
				<div class="ui-toggle-content">
					<h3>Report</h3>
					<div class="col-row auto-col-clear">
						<div class="col s12 m6 l4" v-for="(entry, type, index) in report.entries" :key="type" v-show="entry.length">
							<div class="tracking">
								<p class="type" v-text="$_.startCase($_.replace(type, '_', ' '))"></p>
								<div class="qa" v-for="q, i in entry">
									<p class="q" v-text="getQuestionByKey(q.tracking_type, q.tracking_key)"></p>
									<p class="a" v-text="q.value"></p>
								</div>
							</div>
						</div>
					</div>
					<h3>Progress Ratings</h3>
					<div class="list-report-ratings clearfix">
						<div class="rating" v-for="rating, ratingIndex in report.progress_ratings">
							<div class="tracking">
								<p class="type" v-text="getQuestionByKey(rating.tracking_type, rating.tracking_key)"></p>
								<p v-text="rating.value"></p>
							</div>
						</div>
					</div>
					<h3>Photo Upload</h3>
					<figure v-if="report.photo_upload.image">
						<img :src="report.photo_upload.image" width="300" height="300">
					</figure>
				</div>
			</div>
		</div>
		<p v-if="!hasReports && is_admin_view">No reports to view</p>
		<p v-if="!hasReports && !is_admin_view">You have not created any progress reports</p>
	</div>
</template>
<script>
	import {userApi, adminApi} from '../../../api';
	export default {
		props: {
			user_id: {
				required: true
			},
			is_admin_view: {
				required: false,
				default: false
			},
			list_reports: {
				required: true
			},
			list_images: {
				required: false
			}
		},
		data() {
			return {
				initNewReportModal: false,
				is_submitting_report: false,
				is_processing_image: false,
				model: {},
				reports: {},
				images: {},
				imagename: '',
				report_completed: false,
			}
		},
		methods: {
			uploadPhoto($event) {
				let self = this,
					files = event.target.files,
					file_id = self.model.photo_upload;
				$('[data-type="photo_upload"] .error').remove();
				let formData = new FormData();
				formData.append('photo_upload', files[0]);
				self.imagename = files[0].name;
				if(file_id) {
					userApi.deleteFile(file_id);
				}
				let newReportBtn = $('#new-report button[type="submit"]');
				newReportBtn.attr('disabled', true);
				self.is_processing_image = true;
				userApi.uploadProgressReportImage(formData).then(res => {
					self.model.photo_upload = res.data.attach_id;
					self.$forceUpdate();
					alert('Image has been uploaded, you can now submit your report');
					newReportBtn.attr('disabled', false);
					self.is_processing_image = false;
				}, (error) => {
					console.log(error);
					newReportBtn.attr('disabled', false);
					self.is_processing_image = false;
					if(error.response.data.error) {
						$('[data-field="image_id"][data-type="photo_upload"]').append('<span class="error">' + error.response.data.message + '</span>');
					}
				});
			},
			newReport() {
				let self = this;
				self.is_submitting_report = true;
				$('.error').remove();
				userApi.submitReport(self.model).then(res => {
					self.report_completed = true;
					setTimeout(() => {
						window.location.reload();
					}, 3000);
				}, (error) => {
					let errors = error.response.data;
					console.log(errors);
					Object.entries(errors.errors).forEach((values, index) => {
						var type = values[0], errors = values[1];
						$.each(errors, function(key, error) {
							$('[data-field="' + key + '"][data-type="' + type + '"]').append('<span class="error">' + error + '</span>');
						})
					});
					self.is_submitting_report = false;
				});
			},
			deleteReport(report, idx) {
				let self = this, confirmDelete = confirm("Are you sure you want to delete report: " + self.$moment(report.date).format('DD MMM YYYY'));
				report.deleting = true;
				if(confirmDelete) {
					adminApi.deleteReport(self.user_id, report.report_id).then(res => {
						alert("Report has successfully been deleted");
						self.reports.splice(idx, 1);
						// remove report image
						let report_image = report.photo_upload;
						report.photo_upload.thumb_id;
						if(report_image.thumb_id) {
							self.images.splice(self.$_.findIndex(self.images, {'id': report_image.thumb_id }), 1);
						}
						report.deleting = false;
					}, (error) => {
						let errors = error.response.data;
						console.log(errors);
						alert("Failed to delete report");
						report.deleting = false;
					});
				} else {
					report.deleting = false;
				}
			},
			getQuestionByKey(type, key = null) {
				let self = this, questions = self.$lists.questions.progress[type];
				if(key) {
					return questions[key];
				}
				return questions;
			},
			initMounted() {
				let self = this;
				self.reports = JSON.parse(self.list_reports);
				if(self.list_images) {
					self.images = JSON.parse(self.list_images);
				}
				// init model
				$.each(self.$lists.questions.progress, function(type, questions) {
					self.model[type] = {};
					$.each(questions, function(k, q) {
						self.model[type][k] = "";
					});
					self.model['photo_upload'] = 0;
				});
				self.initNewReportModal = true;
			}
		},
		computed: {
			hasReports() {
				let self = this;
				return self.reports.length > 0;
			}
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>