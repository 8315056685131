<template>
	<form id="upload-avatar" enctype="multipart/form-data">
		<input type="file" name="file" accept="image/*" @change="uploadAvatar($event)">
	</form>
</template>
<script>
	import {userApi} from '../../../api';
	export default {
		data() {
			return {
			}
		},
		methods: {
			showProgressCursor(state) {
				if(state) {
					$('*').css('cursor', 'wait');
				} else {
					$('*').css('cursor', 'default');
				}
			},
			uploadAvatar(event) {
				let self = this,
					files = event.target.files;
				if(files.length) {
					let file = files[0];
					let data = new FormData();
					data.append('file', file);
					self.showProgressCursor(true);
					userApi.uploadAvatar(data).then(res => {
						if(!res.data.error) {
							$('#user-icon figure').find('img').remove();
							$('#user-icon figure').append(res.data.avatar);
							$('#upload-avatar')[0].reset();
							self.showProgressCursor(false);
						}
					}, (error) => {
						console.log(error);
						alert(error.response.data.message);
						self.showProgressCursor(false);
					});
				}
			},
			initMounted() {
				let self = this;
			}
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>