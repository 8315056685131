import http from '../http';

const userApi = {
	auth(data) {
		return http.post('/user/auth', data);
	},
	register(data) {
		return http.post('/user/register', data);
	},
	update(data) {
		return http.put('/user', data);
	},
	submitPreConsultation(data) {
		return http.post('/user/submit-pre-consultation-form', data);
	},
	submitReport(data) {
		return http.post('/user/submit-progress-report', data);
	},
	uploadProgressReportImage(data) {
		let config = {
			header: {
				'Content-Type' : 'multipart/form-data'
			}
		}
		return http.post('/user/upload-progress-report-image', data, config);
	},
	forgotPassword(data) {
		return http.post('/user/forgot-password', data);
	},
	resetPassword(data) {
		return http.post('/user/reset-password', data);
	},
	removeMyAccount() {
		return http.delete('/user');
	},
	uploadAvatar(data) {
		let config = {
			header: {
				'Content-Type' : 'multipart/form-data'
			}
		}
		return http.post('/user/upload-avatar', data, config);
	},
	deleteFile(file_id) {
		return http.delete(`/user/file/${file_id}`);
	},
}

const adminApi = {
	sendServiceList(user_id, data) {
		return http.post(`/admin/user/${user_id}/send-service-list`, data);
	},
	sendPreConsultationForm(user_id) {
		return http.post(`/admin/user/${user_id}/send-pre-consultation-form`);
	},
	deleteEnquiry(user_id) {
		return http.delete(`/admin/user/${user_id}/delete-enquiry`);
	},
	deleteReport(user_id, report_id) {
		return http.delete(`/admin/user/${user_id}/report/${report_id}`);
	},
	markCalledBack(user_id) {
		return http.post(`/admin/user/${user_id}/mark-called-back`);
	},
	createStripePlan(user_id, data) {
		return http.post(`/admin/user/${user_id}/create-stripe-plan`, data);
	},
	sendStripePaymentRequest(user_id, data) {
		return http.post(`/admin/user/${user_id}/send-stripe-payment-request`, data);
	},
	cancelSubscription(user_id) {
		return http.post(`/admin/user/${user_id}/cancel-subscription`);
	},
	uploadTrainingDocs(user_id, data) {
		let config = {
			header: {
				'Content-Type' : 'multipart/form-data'
			}
		}
		return http.post(`/admin/user/${user_id}/upload-training-documents`, data, config);
	},
}

const contactApi = {
	contact(data) {
		return http.post('/contact', data);
	}
}

const recaptchaApi = {
	validateRecaptcha(token) {
		return http.post('/validate-recaptcha', {token: token});
	}
}

const postsApi = {
	getPosts(args) {
		return http.get('/posts?' + $.param(args));
	},
	getCases(args) {
		return http.get('/case-studies?' + $.param(args));
	},
	getVideos(args) {
		return http.get('/videos?' + $.param(args));
	},
	getPostsByTax(tax = 'category', slug, args) {
		return http.get(`/${tax}/${slug}/posts?` + $.param(args));
	},
	searchPosts(args) {
		return http.get('/search/posts?' + $.param(args));
	}
}

const termsApi = {
	getTermsByTax(args) {
		return http.get('/terms?' + $.param(args));
	},
}

export {
	userApi,
	contactApi,
	recaptchaApi,
	postsApi,
	termsApi,
	adminApi
}