<template>
	<div id="contact-form">
		<form @submit.prevent="processContact">
			<div class="col-row">
				<div class="col l6 m6 s12">
					<h6 class="heading-line">
						My details
					</h6>
					<div class="form-block">
						<label id="your-firstname" class="form-label required">
							<span class="label">Firstname</span>
							<div class="form-field input">
								<input v-model="model.firstname" type="text" class="field">
							</div>
						</label>
					</div>
					<div class="form-block">
						<label id="your-surname" class="form-label required">
							<span class="label">Surname</span>
							<div class="form-field input">
								<input v-model="model.surname" type="text" class="field">
							</div>
						</label>
					</div>
					<div class="form-block">
						<label id="your-email" class="form-label required">
							<span class="label">Email address</span>
							<div class="form-field input">
								<input v-model="model.email" type="email" maxlength="64" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" class="field">
							</div>
						</label>
					</div>
					<div class="form-block">
						<label id="your-phone" class="form-label required">
							<span class="label">Phone</span>
							<div class="form-field input">
								<input v-model="model.phone" type="tel" maxlength="13" class="field">
							</div>
						</label>
					</div>
				</div>
				<div class="col l6 m6 s12">
					<h6 class="heading-line">
						My enquiry
					</h6>
					<div class="form-block">
						<div id="your-service" class="form-label required">
							<span class="label">I am interested in</span>
							<div class="ui-select">
								<span class="select-label">{{ model.service && model.service.length > 0 ? model.service.join(', ') : 'Select service' }}</span>
								<div class="select-dropdown">
									<div class="item" v-for="service, index in list_services">
										<div class="ui-checkbox">
											<label>
												<input type="checkbox" :value="service.post_title" v-model="model.service" />
												<span class="checkbox"></span>
												<span v-text="service.post_title"></span>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form-block">
						<label id="your-details" class="form-label">
							<span class="label">Additional Details</span>
							<div class="form-field textarea">
								<textarea v-model="model.message" class="field"></textarea>
							</div>
						</label>
					</div>
					<div class="form-block">
						<div id="your-terms" class="form-label required">
							<div class="ui-checkbox">
								<label>
									<input type="checkbox" v-model="model.newsletter">
									<span class="checkbox"></span>
									<span>Sign up to our newsletter</span>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="submit">
				<button type="submit" class="ui-btn ui-btn-block" v-text="sending ? 'Sending...' : 'Enquire Now'" :disabled="sending"></button>
			</div>
		</form>
	</div>
</template>
<script>
	import helper from '../../helpers';
	import {contactApi} from '../../api';
	export default {
		data () {
			return {
				sending: false,
				list_services: [],
				model: {}
			}
		},
		methods: {
			contact() {
				let self = this;
				self.sending = true;
				$('span.error').remove();
				contactApi.contact(self.model).then(response => {
					self.sending = false;
					let res = response.data;
					if(!res.error) {
						alert(res.message);
						self.initModel();
					}
				}, (error) => {
					self.sending = false;
					let res = error.response.data;
					if(res.error) {
						if(res.hasOwnProperty("errors")) {
							res.errors.forEach((e) => {
								$('#' + e.key).append('<span class="error">' + e.error + '</span>');
							});
						}
					}
					if(res.message) {
						alert(res.message);
					}
					console.log(error);
				});
			},
			processContact() {
				let self = this;
				self.contact();
			},
			initModel() {
				let self = this;
				self.model = {
					firstname: "",
					surname: "",
					email: "",
					phone: "",
					service: [],
					message: "",
					newsletter: false
				}
			},
			initMounted() {
				let self = this;
				self.initModel();
			}
		},
		mounted() {
			let self = this;
			self.list_services = self.$lists.services;
			self.initMounted();
		}
	}
</script>