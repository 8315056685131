<template>
	<div id="user">
		<div class="col-row">
			<div class="col s12 m12 l8">
				<div class="ui-tabs">
					<div class="ui-tab-controls">
						<span class="ui-tab-btn active">Client Details</span>
						<span class="ui-tab-btn">Goals</span>
						<span class="ui-tab-btn">Training</span>
						<span class="ui-tab-btn">Nutrition</span>
						<span class="ui-tab-btn">Support</span>
					</div>
					<div class="ui-tab-content">
						<div class="ui-tab meta-box">
							<table>
								<tr>
									<td>Firstname</td>
									<td><input type="text" readonly disabled v-model="usermeta.first_name" data-field="first_name"></td>
								</tr>
								<tr>
									<td>Surname</td>
									<td><input type="text" readonly disabled v-model="usermeta.last_name" data-field="last_name"></td>
								</tr>
								<tr>
									<td>Email</td>
									<td><input type="text" readonly disabled v-model="usermeta.email" data-field="email"></td>
								</tr>
								<tr>
									<td>Phone</td>
									<td><input type="tel" pattern="[0-9]*" minlength="11" maxlength="11" v-model="usermeta.phone" data-field="phone"></td>
								</tr>
								<tr>
									<td>DOB</td>
									<td><input type="text" readonly disabled v-model="usermeta.dob" data-field="dob"></td>
								</tr>
								<tr>
									<td>Height</td>
									<td>
										<div class="col-row">
											<div class="col col-6">
												<label>Ft <input type="number" min="3" max="7" v-model="usermeta.height_ft" data-field="height_ft"></label>
											</div>
											<div class="col col-6">
												<label>Inches <input type="number" min="1" max="12" v-model="usermeta.height_inches" data-field="height_inches"></label>
											</div>
										</div>
									</td>
								</tr>
								<tr>
									<td>Weight</td>
									<td>
										<label>
											Lbs
											<input type="number" min="0" v-model="usermeta.weight_lbs" data-field="weight_lbs">
										</label>
									</td>
								</tr>
								<tr>
									<td>Occupation</td>
									<td><input type="text" v-model="usermeta.occupation" data-field="occupation"></td>
								</tr>
								<tr>
									<td>Alcohol</td>
									<td>
										<fieldset class="radios" data-field="alcohol">
											<label><input type="radio" v-model="usermeta.alcohol" value="Yes" /> Yes</label>
											<label><input type="radio" v-model="usermeta.alcohol" value="No" /> No</label>
										</fieldset>
									</td>
								</tr>
								<tr>
									<td>Smokes</td>
									<td>
										<fieldset class="radios" data-field="smoke">
											<label><input type="radio" v-model="usermeta.smoke" value="Yes" /> Yes</label>
											<label><input type="radio" v-model="usermeta.smoke" value="No" /> No</label>
										</fieldset>
									</td>
								</tr>
								<tr>
									<td>Medical conditions</td>
									<td><textarea v-model="usermeta.medical_conditions" cols="50" rows="2" data-field="medical_conditions"></textarea></td>
								</tr>
								<tr>
									<td>Current injuries</td>
									<td><textarea v-model="usermeta.current_injuries" cols="50" rows="2" data-field="current_injuries"></textarea></td>
								</tr>
							</table>
						</div>
						<div class="ui-tab meta-box">
							<table>
								<tr>
									<td>Goals</td>
									<td>
										<span>Please number these statements from 1 to {{$lists.questions.goals.length}} in order of importance to you, where 1 = most important</span>
										<ul v-if="usermeta.goals" class="list-goals" data-field="goals">
											<li v-for="goalIdxValue, goalKey in usermeta.goals">
												<select v-model="usermeta.goals[goalKey]">
													<option v-for="goalIdx in $lists.questions.goals.length" :value="goalIdx" v-text="goalIdx"></option>
												</select>
												{{ getGoalLabel(goalKey) }}
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td>Other goals</td>
									<td><textarea v-model="usermeta.other_goals" cols="50" rows="2" data-field="other_goals"></textarea></td>
								</tr>
								<tr>
									<td>Short term goals</td>
									<td><textarea v-model="usermeta.short_term_goals" cols="50" rows="2" data-field="short_term_goals"></textarea></td>
								</tr>
								<tr>
									<td>Long term goals</td>
									<td><textarea v-model="usermeta.long_term_goals" cols="50" rows="2" data-field="long_term_goals"></textarea></td>
								</tr>
								<tr>
									<td>Goal barriers</td>
									<td><textarea v-model="usermeta.goal_barriers" cols="50" rows="2" data-field="goal_barriers"></textarea></td>
								</tr>
							</table>
						</div>
						<div class="ui-tab meta-box">
							<table>
								<tr>
									<td>Weekly exercise lifestyle</td>
									<td><textarea v-model="usermeta.weekly_exercise_lifestyle" cols="50" rows="2" data-field="weekly_exercise_lifestyle"></textarea></td>
								</tr>
								<tr>
									<td>Elaborate exercise</td>
									<td><textarea v-model="usermeta.elaborate_exercise" cols="50" rows="2" data-field="elaborate_exercise"></textarea></td>
								</tr>
								<tr>
									<td>Average week lifestyle</td>
									<td><textarea v-model="usermeta.average_week_lifestyle" cols="50" rows="2" data-field="average_week_lifestyle"></textarea></td>
								</tr>
							</table>
						</div>
						<div class="ui-tab meta-box">
							<table>
								<tr>
									<td>Average nutritional intake</td>
									<td><textarea v-model="usermeta.average_nutritional_intake" cols="50" rows="2" data-field="average_nutritional_intake"></textarea></td>
								</tr>
								<tr>
									<td>Food allergies intolerances</td>
									<td><textarea v-model="usermeta.food_allergies_intolerances" cols="50" rows="2" data-field="food_allergies_intolerances"></textarea></td>
								</tr>
								<tr>
									<td>Water perday</td>
									<td><textarea v-model="usermeta.water_perday" cols="50" rows="2" data-field="water_perday"></textarea></td>
								</tr>
							</table>
						</div>
						<div class="ui-tab meta-box">
							<table>
								<tr>
									<td>Support my goals</td>
									<td><textarea v-model="usermeta.support_my_goals" cols="50" rows="2" data-field="support_my_goals"></textarea></td>
								</tr>
								<tr>
									<td>Package budget</td>
									<td><textarea v-model="usermeta.package_budget" cols="50" rows="2" data-field="package_budget"></textarea></td>
								</tr>
								<tr>
									<td>Hear about us</td>
									<td><textarea v-model="usermeta.hear_about_us" cols="50" rows="2" data-field="hear_about_us"></textarea></td>
								</tr>
								<tr>
									<td>Provide testimonial</td>
									<td><textarea v-model="usermeta.provide_testimonial" cols="50" rows="2" data-field="provide_testimonial"></textarea></td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="col s12 m12 l4">
				<aside>
					<h4>Actions</h4>
					<div class="meta-box">
						<button type="button" class="ui-btn" @click="saveProfile()" :disabled="is_saving_profile" v-text="is_saving_profile ? 'Saving...' : 'Save profile'"></button>
					</div>
					<div class="meta-box">
						<h4 class="meta-title">My Services</h4>
						<p v-for="service, index in usermeta.services_required" v-text="service"></p>
					</div>
				</aside>
			</div>
		</div>
	</div>
</template>
<script>
	import {userApi} from '../../api';
	export default {
		props: {
			user_meta: {
				required: true
			}
		},
		data() {
			return {
				is_saving_profile: false,
				usermeta: {},
			}
		},
		methods: {
			getGoalLabel(key) {
				let self = this,
					goals = self.$lists.questions.goals,
					goal = self.$_.filter(goals, {'key' : key});
				return goal[0].label;
			},
			saveProfile() {
				let self = this, usermeta = self.usermeta, model = {};
				self.is_saving_profile = true;
				$('.error').remove();
				let keysToUpdate = [
					'phone',
					'occupation',
					'height_ft',
					'height_inches',
					'weight_lbs',
					'alcohol',
					'smoke',
					'medical_conditions',
					'current_injuries',
					'other_goals',
					'short_term_goals',
					'long_term_goals',
					'goal_barriers',
					'weekly_exercise_lifestyle',
					'elaborate_exercise',
					'average_week_lifestyle',
					'average_nutritional_intake',
					'food_allergies_intolerances',
					'water_perday',
					'support_my_goals',
					'package_budget',
					'hear_about_us',
					'provide_testimonial',
					'goals'
				];
				$.each(keysToUpdate, function(i, v) {
					model[v] = usermeta[v];
				});
				userApi.update(model).then(res => {
					self.is_saving_profile = false;
					alert('Profile updated');
				}, (error) => {
					let errors = error.response.data;
					console.log(errors);
					Object.entries(errors.errors).forEach((value, index) => {
						$('<span class="error">' + value[1] + '</span>').insertAfter('[data-field="' + value[0] + '"]');
					});
					var idx = $('[data-field="' + Object.keys(errors.errors)[0] + '"]').parents('.ui-tab').index();
					$('.ui-tab-btn').eq(idx).trigger('click');
					self.is_saving_profile = false;
				});
			},
			initMounted() {
				let self = this;
				self.usermeta = JSON.parse(self.user_meta);
				$('textarea').on('focus', function() {
					$(this).attr('rows', 5);
				});
				$('textarea').on('blur', function() {
					$(this).attr('rows', 2);
				});
			}
		},
		computed: {
			implodeServices() {
				let self = this;
				if(self.usermeta.services_required) {
					return self.usermeta.services_required.join(', ');
				} else {
					return '';
				}
			},
			hasTrainingDocs() {
				let self = this;
				return self.usermeta.training_documents.length > 0;
			}
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>