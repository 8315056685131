import '../sass/sass.scss';
import './functions.js';
import _ from 'lodash'; 
import moment from 'moment';

import {createApp} from 'vue';
const vue = createApp({});

vue.config.globalProperties.$app = app;
vue.config.globalProperties.$lists = lists;
vue.config.globalProperties.$_ = _;
vue.config.globalProperties.$moment = moment;

// auth
vue.component('login', require('./components/login').default);
vue.component('forgot-password', require('./components/forgot-password').default);
vue.component('reset-password', require('./components/reset-password').default);
vue.component('contact', require('./components/contact').default);

// services/posts
vue.component('list-cases', require('./components/posts/list-cases').default);
vue.component('list-search-results', require('./components/posts/list-search-results').default);
vue.component('list-videos', require('./components/posts/list-videos').default);
vue.component('list-tax-posts', require('./components/posts/list-tax-posts').default);

// user
vue.component('user', require('./components/user').default);
vue.component('user-pre-consultation-form', require('./components/user/pre-consultation-form').default);
vue.component('user-upload-avatar', require('./components/user/upload-avatar').default);
vue.component('user-training-documents', require('./components/user/training-documents').default);
vue.component('user-progress-reports', require('./components/user/progress-reports').default);
vue.component('admin-edit-user', require('./components/admin/edit-user').default);

vue.mount('#app');