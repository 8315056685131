<template>
	<div id="list-user-docs" v-if="hasTrainingDocs" class="col-row auto-col-clear">
		<div class="col s12 m6 l4 xl3" v-for="doc, index in docs" :title="doc.doc_title">
			<div class="doc v-middle">
				<div class="file v-content">
					<span :class="'fa fa-file' + getFontAwesomeIcon(doc.doc_type)"></span>
					<p v-text="$_.truncate(doc.doc_title.split('.')[0]) + '.' + doc.doc_type"></p>
					<a :href="$app.api_url + '/user/download-training-document/' + doc.doc_id" class="ui-btn download" download>Download</a>
				</div>
			</div>
		</div>
	</div>
	<p v-if="!hasTrainingDocs">You have no training documents to view</p>
</template>
<script>
	export default {
		props: {
			training_documents: {
				required: true
			},
		},
		data() {
			return {
				docs: {},
			}
		},
		methods: {
			getFontAwesomeIcon(type) {
				let icon = null;
				switch(type) {
					case 'pdf':
						icon = '-pdf';
						break;
					case 'doc':
						icon = '-word';
						break;
					case 'docx':
						icon = '-word';
						break;
					case 'xls':
						icon = '-excel';
						break;
					case 'xlsx':
						icon = '-excel';
						break;
					default:
						icon = '';
						break;
				}
				return icon;
			},
			initMounted() {
				let self = this;
				self.docs = JSON.parse(self.training_documents);
			}
		},
		computed: {
			hasTrainingDocs() {
				let self = this;
				return self.docs.length > 0;
			}
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>