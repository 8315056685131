<template>
	<section id="videos">
		<div class="inner">
			<div id="video-filters" v-if="filters.length">
				<p>Use the categories below to filter through our video gallery.</p>
				<button v-for="filter, index in filters" :key="`filter-${index}`" class="ui-btn ui-btn-outline ui-btn-rounded" :class="{'active': filterValues.indexOf(filter.slug) > -1 }" :disabled="isLoading">
					<input type="radio" name="filters" v-model="filterValues" :value="filter.slug">
					{{ filter.name }}
				</button>
			</div>
			<div class="list-videos col-row auto-col-clear" v-if="posts.length">
				<div class="col l3 m4 s6 xs12" v-for="post, index in posts" :key="`post-${index}`">
					<div class="video">
						<figure class="img respond">
							<a :href="post.guid"><img :src="post.image" :alt="post.title"></a>
						</figure>
						<div class="content">
							<h3 class="title"><a :href="post.guid" v-text="post.title"></a></h3>
							<time v-text="timeAgo(post.date)"></time>
						</div>
					</div>
				</div>
			</div>
			<div class="no-posts ui-text-center" v-if="!total_posts && !isLoading">
				<p>No videos</p>
			</div>
		</div>
		<div class="load-more ui-text-center" v-if="total_posts || !isFinished">
			You are viewing <span class="viewing" v-text="posts.length"></span> of <span class="total" v-text="total_posts"></span> videos
			<div class="progress-wrapper">
				<div class="progress">
					<div class="bar">&nbsp;</div>
				</div>
			</div>
			<button type="button" class="ui-btn ui-btn-outline" @click="loadMore" :disabled="isFinished || isLoading" v-text="isLoading ? 'Loading...' : 'Load more'"></button>
		</div>
	</section>
</template>
<script>
	import {postsApi, termsApi} from '../../../api';
	import helper from '../../../helpers';
	import moment from 'moment';
	export default {
		data() {
			return {
				page: 1,
				per_page: 16,
				total_pages: 0,
				total_posts: 0,
				posts: [],
				isLoading: false,
				isFinished: false,
				filters: [],
				filterValues: []
			}
		},
		watch: {
			'filterValues': function(val, oldVal) {
				let self = this;
				self.filterVideos();
			}
		},
		methods: {
			getTerms() {
				let self = this;
				let args = {
					page: 1,
					per_page: 50,
					taxonomy: 'video-category',
					image_size: 'thumb-video-rs'
				}
				termsApi.getTermsByTax(args).then(response => {
					self.filters = response.data.terms;
				}, error => {
					console.log(error);
				});
			},
			getVideos() {
				let self = this,
				args = {
					page: self.page,
					per_page: self.per_page,
					offset: 0,
					image_size: 'large',
					taxonomy: 'video-category',
					//terms: self.filterValues.join(';')
					terms: self.filterValues
				}
				self.isLoading = true;
				postsApi.getVideos(args).then(response => {
					let data = response.data, percent = 0;
					self.isLoading = false;
					self.total_pages = data.total_pages;
					self.total_posts = data.total_posts;
					percent = (100 / (data.total_posts / (self.per_page * self.page))).toFixed(0);
					if(data.total_posts === 0) {
						self.isFinished = true;
					} else {
						self.posts.push.apply(self.posts, data.posts);
						if(self.page === data.total_pages) {
							self.isFinished = true;
						}
						self.page += 1;
					}
					$('.progress .bar').width(percent + '%');
				}, error => {
					console.log(error);
				});
			},
			timeAgo(date) {
				return moment(date).fromNow();
			},
			loadMore() {
				let self = this;
				self.getVideos();
			},
			initMounted() {
				let self = this;
				self.getTerms();
				self.getVideos();
			},
			filterVideos() {
				let self = this;
				self.reset();
			},
			reset() {
				let self = this;
				self.page = 1;
				self.total_pages = 0;
				self.posts = [];
				self.isFinished = false;
				$('.progress .bar').width(0);
				self.getVideos();
			}
		},
		computed: {
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>